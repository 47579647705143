import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FormEvent } from 'react';



interface ModalTWProps {
  title: string;
  okLabel: string;
  okDisabled?: boolean;
  show: boolean;
  callbackClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>, callbackClose: () => void) => void;
  body: React.ReactNode;
  modalType?: 'warning'
}

export function ModalTW({
  title,
  okLabel,
  okDisabled,
  show,
  callbackClose,
  onSubmit,
  body,
  modalType,
}: ModalTWProps) {

  // free component from https://tailwindui.com/components/application-ui/overlays/modal-dialogs

  // the position:fixed black navbar is z-index:10, so we use z-50 here to make sure the modal is on top

  return (
    <Dialog open={show} onClose={callbackClose} className='tw-relative tw-z-50'>
      <DialogBackdrop
        transition
        className='tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in'
      />

      <div className='tw-fixed tw-inset-0 tw-z-50 tw-w-screen tw-overflow-y-auto'>
        <div className='tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0'>
          <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
            //e.preventDefault()
            onSubmit(e, () => callbackClose())
          }}>
            <DialogPanel
              transition
              className='tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl tw-transition-all data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-my-8 sm:tw-w-full _disabled_sm:tw-max-w-lg data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95'
            >
              <div className='tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4'>
                <div className='sm:tw-flex sm:tw-items-start'>
                  {modalType === 'warning' && <div className='tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10'>
                    <ExclamationTriangleIcon aria-hidden='true' className='tw-h-6 tw-w-6 tw-text-red-600' />
                  </div>}
                  <div className='tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left'>
                    <DialogTitle as='h3' className='tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900'>
                      {title}
                    </DialogTitle>
                    <div className='tw-mt-2'>
                      {/* <p className='tw-text-sm tw-text-gray-500'>
                      Are you sure you want to deactivate your account? All of your data will be permanently removed.
                      This action cannot be undone.
                    </p> */}
                      {body}
                    </div>
                  </div>
                </div>
              </div>
              <div className='tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-flex sm:tw-flex-row-reverse sm:tw-px-6'>
                <button
                  type='submit'
                  disabled={okDisabled}
                  className={`tw-inline-flex tw-w-full tw-justify-center tw-rounded-md ${okDisabled ? 'tw-bg-slate-400' : 'tw-bg-red-600 hover:tw-bg-red-500'} tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm sm:tw-ml-3 sm:tw-w-auto`}
                >
                  {okLabel}
                </button>
                <button
                  type='button'
                  data-autofocus
                  onClick={callbackClose}
                  className='tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto'
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </form>

        </div>
      </div>
    </Dialog>
  )
}

