import { ReactNode } from 'react';
import { DateInput } from 'src/pages/ExpenseSheet/DateInput';
import { dateFormatJpShort } from 'src/util/dateformattools';
import { jst0_from_local0, local0_or_null_from_jst0 } from 'src/util/datetools';
import { getEditableFieldBorderStyle } from '../EditableField/util_borderstyle';



interface EditableFieldDatepickerProps {
  currentValue_jst0: any;
  isClickableToEdit: boolean;
  callbackCommitChange: (date_jst0: Date | null) => void;
  useSpan?: boolean;
  showBorder?: boolean;
}

export function EditableFieldDatepicker({
  currentValue_jst0,
  isClickableToEdit,
  callbackCommitChange, // save value AND end editing (=setEditedField(''))
  useSpan,
  showBorder,
}: EditableFieldDatepickerProps) {


  const currentlyEditing = isClickableToEdit


  if (!currentlyEditing) {

    const textContents: ReactNode = dateFormatJpShort(currentValue_jst0) || ''

    // <span> is required for amount and currency in invoice table, so that they appear on the same line
    // <div> is preferred in expense sheet tables, to make it easier to click to edit the cell
    //   (<div> occupies the entire cell width whereas <span> only covers the text itself, which can
    //   be difficult to click if the text is short)
    if (!useSpan) {
      return (
        <div className={showBorder ? getEditableFieldBorderStyle(false) : ''}>
          {textContents}
        </div>
      )
    } else {
      return (
        <span>
          {textContents}
        </span>
      )
    }

    // ↑ NOT EDITING
  } else { // ----------------------------------------------------------------------------------------
    // ↓ EDITING

    return (
      <div className={showBorder ? `${getEditableFieldBorderStyle(true)} tw-p-1` : ''}>
        <DateInput value_local0={local0_or_null_from_jst0(currentValue_jst0)} onChange={(date_local0) => {
          const date_jst0 = date_local0 ? jst0_from_local0(date_local0) : null
          callbackCommitChange(date_jst0)
        }} />
      </div>
    )

  }
}

