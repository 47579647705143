


interface ButtonUpDownProps {
  disableUp: boolean;
  disableDown: boolean;
  onClickUp: () => void;
  onClickDown: () => void;
}

export function ButtonUpDown({
  disableUp,
  disableDown,
  onClickUp,
  onClickDown,
}: ButtonUpDownProps) {


  return (
    <div key='0' className='tw-flex tw-gap-1 tw-flex-col'>
      <button
        disabled={disableUp}
        className={`tw-bg-transparent tw-border tw-border-solid ${!disableUp ? 'tw-border-blue-500 tw-text-blue-500 hover:tw-bg-blue-500 hover:tw-text-white' : 'tw-border-slate-400 tw-text-slate-400'} tw-rounded tw-h-5 tw-py-0`}
        onClick={onClickUp}>
        <i className='bi bi-chevron-up tw-block tw-mt-[-0.2em]'></i>
      </button>
      <button
        disabled={disableDown}
        className={`tw-bg-transparent tw-border tw-border-solid ${!disableDown ? 'tw-border-blue-500 tw-text-blue-500 hover:tw-bg-blue-500 hover:tw-text-white' : 'tw-border-slate-400 tw-text-slate-400'} tw-rounded tw-h-5 tw-pt-[-1em]`}
        onClick={onClickDown}>
        <i className='bi bi-chevron-down tw-block tw-mt-[-0.2em]'></i>
      </button>
    </div>
  )
}
