import { ChangeEvent, ClipboardEvent, KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { formatNum } from 'src/util/util_formatnum';
import { isDevMode } from 'src/util/util_getbuildtime';
import { validateInput } from 'src/util/util_validateinput';
import { convertLfToBr } from './util_textformatting';
import { useOutsideAlerter } from './util_useOutsideAlerter';


export type ValidationType = '' | 'number' | 'percent' | 'formula' | 'date' | 'dateOrNull' | 'dateiso' | 'currency'

interface EditableFieldProps {
  tableid?: string;
  rowid?: string;
  fieldname: string;
  validationType: ValidationType;
  currentValue: string | number | null;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChange: (value: any, tabKey?: -1 | 1, formula?: string) => void;
  isTextArea?: boolean;
  textareaRows?: number;
  useSpan?: boolean;
  hasButtonForEditing?: boolean;
  callbackOnChange?: (value: string) => void;
  placeholderText?: string;
  callbackOnPaste?: (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  getDisplayValue?: (value: any) => any;
  customValidator?: (value: string) => [boolean, any, string];
}

export function EditableField({
  tableid,
  rowid,
  fieldname,
  validationType,
  currentValue,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChange, // save value AND end editing (=setEditedField(''))
  isTextArea,
  textareaRows,
  useSpan,
  hasButtonForEditing,
  callbackOnChange, // on each key press
  placeholderText, // e.g. 'Click here to edit memo'
  callbackOnPaste,
  getDisplayValue, // e.g. for parsing image tags and displaying actual images
  customValidator,
}: EditableFieldProps) {

  // detect iPhone, iPad, etc:
  const isTouchScreen = window.matchMedia('(pointer: coarse)').matches // https://stackoverflow.com/a/52855084/

  const validateInput0 = useCallback((validationType: ValidationType, value: string, caretPos?: number) => {
    if (customValidator) {
      return customValidator(value)
    } else {
      return validateInput(validationType, value, caretPos)
    }
  }, [customValidator])

  const tryCommit = (value: any, tabKey?: -1 | 1) => {
    // tryCommit is when user tries to commit value to object/db, i.e.
    // for text inputs, when form is submitted (user presses Enter, or [new behavior] user clicks anywhere outside the textbox)
    const [isValid, dbvalue] = validateInput0(validationType, value)
    if (!isValid)
      return

    callbackCommitChange(dbvalue, tabKey,
      (validationType === 'formula' && typeof value === 'string' && value.startsWith('=')) ? value : undefined)
  }

  // convert currentValue to string and store it in currentValueFormatted
  let currentValueFormatted: string
  if (currentValue === undefined || currentValue === null) {
    currentValueFormatted = ''
  } else if (typeof currentValue === 'string') {
    currentValueFormatted = currentValue
  } else if (typeof currentValue === 'number') {
    currentValueFormatted = formatNum(currentValue)
  } else {
    if (isDevMode()) throw new Error('EditableField: currentValue must be string or number')
    currentValueFormatted = currentValue as string
  }


  // <input/>'s value (always a formatted string)
  const [textboxValue, setTextboxValue] = useState<string>(currentValueFormatted)

  const [caretPos, setCaretPos] = useState<number>()

  const cellid =
    (tableid ? tableid + '_' : '')
    + (rowid ? rowid + '_' : '')
    + fieldname;


  // the below allows a user to click anywhere on the page, to make the current edited cell to stop being edited
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  // _eslint-disable-next-line react-compiler/react-compiler -- https://github.com/facebook/react/issues/30745
  useOutsideAlerter(inputRef, () => {

    //setEditedCell('') // cancel edit, reject user input, restore to underlying db/object value
    // new behavior is to accept user input when they click away. they can always press ESC to cancel input
    tryCommit(textboxValue)
  });



  // black magic below: re-sync the db value with the textbox value when the db value changes
  useEffect(() => {
    //console.log(`EditableField value updated due to underlying data change: [${currentValue}]`)
    setTextboxValue(currentValueFormatted)
  }, [

    // when db value changes, the textbox value will be reset to the db value (passed to EditableField via `currentValue`)
    // -> this ensures we remain in sync with the database
    currentValueFormatted,

    // when user stops editing this cell, the textbox value will also reset
    // -> this ensures that when they come back to edit later, they see the current db value and not the last user input value
    editedCell,

    validationType,
  ])


  const currentlyEditing = editedCell === cellid

  const [dummy, setDummy] = useState(0)

  useEffect(() => {
    if (currentlyEditing && caretPos !== undefined)
      inputRef.current!.setSelectionRange(caretPos, caretPos)
  })



  if (!currentlyEditing) {

    // to make the space clickable for editing:
    const whitespace = (isClickableToEdit && !hasButtonForEditing) ? '\xa0'.repeat(20) : '' // \xa0 is &nbsp;

    const onclickHandler = (() => {
      if (isClickableToEdit)
        setEditedCell(cellid)
    })

    let displayValue: ReactNode = currentValueFormatted

    if (getDisplayValue) {
      displayValue = getDisplayValue(currentValue)
    } else {
      if (currentValueFormatted && isTextArea) {
        displayValue = convertLfToBr(currentValueFormatted)
      }
    }

    if (!displayValue) {
      if (isClickableToEdit && placeholderText) {
        displayValue = <span style={{ color: 'gray', fontStyle: 'italic' }}>{placeholderText}</span>
      } else {
        displayValue = whitespace
      }
    }

    const textContents =
      <>
        {displayValue}
        {validationType === 'percent' ? '%' : ''}
        {(isClickableToEdit && hasButtonForEditing) && (
          <>
            {' '}
            <i className='bi bi-pencil-square' onClick={onclickHandler}></i>
          </>
        )}
      </>

    // <span> is required for amount and currency in invoice table, so that they appear on the same line
    // <div> is preferred in expense sheet tables, to make it easier to click to edit the cell
    //   (<div> occupies the entire cell width whereas <span> only covers the text itself, which can
    //   be difficult to click if the text is short)
    if (!useSpan) {
      return (
        <div onClick={(isClickableToEdit && !hasButtonForEditing) ? onclickHandler : undefined}>
          {textContents}
        </div>
      )
    } else {
      return (
        <span onClick={(isClickableToEdit && !hasButtonForEditing) ? onclickHandler : undefined}>
          {textContents}
        </span>
      )
    }

    // ↑ NOT EDITING
  } else { // ----------------------------------------------------------------------------------------
    // ↓ EDITING

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // (never called from datepicker)
      const newValue = e.currentTarget.value
      const caretPos = e.currentTarget.selectionStart! // never null for text inputs

      const [isValid, dbvalue, formattedValue, newCaretPos] = validateInput0(validationType, newValue, caretPos)
      setTextboxValue(formattedValue)
      e.target.style.color = isValid ? '' : 'red'

      setCaretPos(newCaretPos) // might be undefined

      // Force useEffect call to set caret position by setting dummy state.
      // Without this dummy state, if user deletes a comma from a number e.g. 123,456 -> 123456,
      // because the number gets reformatted to the same as before with the comma 123456 -> 123,456
      // React thinks the state has not changed and does not call useEffect.
      // But because React had to put the comma back after the user deleted it, the caret gets moved to the end of the input.
      setDummy(Math.random())

      if (callbackOnChange) {
        console.log('callback value', formattedValue)
        callbackOnChange(formattedValue)
      }
    }

    // let inputType = 'text'
    // let controlValue = value
    // if (isDatePicker) {
    //   inputType = 'date'
    //   controlValue = iso_from_jst0(tryParseDateToJst0(currentValue)) || ''
    // }


    const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // console.log('keydown', e)
      // console.log('isComposing', e.nativeEvent.isComposing)
      if (
        //e.key === 'Escape' && 
        e.nativeEvent.isComposing) {
        // Escape key: prevent editing mode from being disabled by setEditedCell('')
        // Escape key: prevent modal from closing
        // Tab key: prevent commiting input and moving to next field
        e.stopPropagation()
        return
      }

      if (e.key === 'Escape') {
        setEditedCell('')
        // prevent modal from closing
        e.stopPropagation()
        return
      }

      if (e.key === 'Tab') {
        e.preventDefault()
        tryCommit(textboxValue, e.shiftKey ? -1 : 1)
        return
      }

      const modifierKey = e.shiftKey || e.altKey || e.ctrlKey || e.metaKey
      if (e.key === 'Enter' && !modifierKey && !(isTextArea && isTouchScreen)) {
        // We ignore the key press here if Shift/Alt/Ctrl/Cmd key is pressed,
        // to allow user to type multiple lines in textarea.
        // However, on iPhone/tablet, Enter alone creates a new line, and we provide a button to commit.
        // (It's not possible to press Shift+Enter on iOS)
        e.preventDefault()
        tryCommit(textboxValue)
        return
      }
    }

    let inputControl = null
    if (isTextArea) {
      let numRows = textboxValue.split('\n').length
      if (textareaRows && numRows < textareaRows) {
        numRows = textareaRows
      }
      inputControl = (
        <>
          <textarea
            ref={(el) => inputRef.current = el} // https://stackoverflow.com/a/59970450/
            value={textboxValue} rows={numRows} className='form-control' autoFocus={true}
            onChange={handleInputChange}
            onKeyDown={inputOnKeyDown}
            onPaste={callbackOnPaste}
          />
          {isTouchScreen ? (
            <div className='text-end'>
              <button className='mt-2 text-end btn btn-outline-primary btn-sm'>Save</button>
            </div>
          ) : (
            <div>Press Shift+Enter to make a new line.</div>
          )}
        </>
      )
    } else {
      inputControl = (
        <input type='text'
          ref={(el) => inputRef.current = el} // https://stackoverflow.com/a/59970450/
          value={textboxValue} className='form-control' autoFocus={true}
          onChange={handleInputChange}
          onKeyDown={inputOnKeyDown}
          onPaste={callbackOnPaste}
        />
      )
    }

    return (
      // <form onSubmit={(e) => {
      //   e.preventDefault()
      //   tryCommit(value)
      // }}>
      <>
        {inputControl}
      </>
      // </form>
    )
  }
}

