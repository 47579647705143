import { Season } from 'src/types/types_tripquotations';
import { formatNum } from 'src/util/util_formatnum';
import { HotelOccupancyType, HotelRoomPricesType } from '../../types/types_supplierprices';
import { seasonColors } from '../Payees/SupplierPrices/util_hotelprices';


function formatNumLax(num: any) {
  if (typeof num === 'number') {
    return formatNum(num)
  } else {
    return num;
  }
}
interface RoomSelectorProps {
  selectedRoom: HotelRoomPricesType | undefined;
  selectedOccupancy: HotelOccupancyType | undefined;
  setSelectedRoom: (room: HotelRoomPricesType) => void;
  setSelectedOccupancy: (occ: HotelOccupancyType) => void;
  roomList: HotelRoomPricesType[];
  seasons: Season[] | null;
  listNumOfPaxPerRoom: number[];
}

export function RoomSelector({
  selectedRoom,
  selectedOccupancy,
  setSelectedRoom,
  setSelectedOccupancy,
  roomList,
  seasons,
  listNumOfPaxPerRoom,
}: RoomSelectorProps) {
  return (
    <div>
      <table className='
                  [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                  [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
        <thead>
          <tr>
            <th className='tw-bg-slate-200'>Room type</th>
            <th className='tw-bg-slate-200'>Sqm per room</th>
            <th className='tw-bg-slate-200'>Pax per room</th>
            <th className={`${seasonColors.lo} ${seasons?.includes('low') ? 'tw-ring-2 tw-ring-red-400 tw-ring-inset' : ''}`}>Low season</th>
            <th className={`${seasonColors.md} ${seasons?.includes('mid') ? 'tw-ring-2 tw-ring-red-400 tw-ring-inset' : ''}`}>Medium season</th>
            <th className={`${seasonColors.hi} ${seasons?.includes('high') ? 'tw-ring-2 tw-ring-red-400 tw-ring-inset' : ''}`}>High season</th>
            <th className={`${seasonColors.sp} ${seasons?.includes('special') ? 'tw-ring-2 tw-ring-red-400 tw-ring-inset' : ''}`}>Special season</th>
          </tr>
        </thead>
        <tbody>
          {roomList.map((room) =>
            room.occupancyList.map((occ) => {

              const isClickableRow = typeof occ.numOfPaxPerRoom === 'number' && listNumOfPaxPerRoom.includes(occ.numOfPaxPerRoom);
              const isSelected = selectedRoom?.roomName === room.roomName && selectedOccupancy?.numOfPaxPerRoom === occ.numOfPaxPerRoom;

              // not selected, no hover: bg-white
              // not selected, hover:    bg-blue-400/20
              // selected,     no hover: bg-blue-400/50
              // selected,     hover:    bg-blue-400/60
              return (
                <tr key={`${room.roomName}_${occ.numOfPaxPerRoom}`}
                  className={`
                    ${isClickableRow ? 'tw-cursor-pointer' : 'tw-text-slate-400'}
                    ${isSelected ? 'tw-bg-blue-400/50 hover:tw-bg-blue-400/60'
                      : isClickableRow ? 'hover:tw-bg-blue-400/20'
                        : ''}`}
                  onClick={() => {
                    if (isClickableRow) {
                      setSelectedRoom(room);
                      setSelectedOccupancy(occ);
                    }
                  }}>
                  <td>{room.roomName}</td>
                  <td>{room.roomSqm}</td>
                  <td>{occ.numOfPaxPerRoom}</td>
                  <td className={seasons?.includes('low') ? 'tw-bg-blue-400/20' : ''}>{formatNumLax(occ.lowSeason)}</td>
                  <td className={seasons?.includes('mid') ? 'tw-bg-blue-400/20' : ''}>{formatNumLax(occ.mediumSeason)}</td>
                  <td className={seasons?.includes('high') ? 'tw-bg-blue-400/20' : ''}>{formatNumLax(occ.highSeason)}</td>
                  <td className={seasons?.includes('special') ? 'tw-bg-blue-400/20' : ''}>{formatNumLax(occ.specialSeason)}</td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}
