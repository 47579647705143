import { EditableField, ValidationType } from 'src/components/EditableField/EditableField';
import { convertLfToBr } from 'src/components/EditableField/util_textformatting';
import { formatNum } from 'src/util/util_formatnum';
import { getEditableFieldBorderStyle } from './util_borderstyle';



interface EditableFieldWithBorderProps {
  tableid?: string;
  rowid?: string;
  fieldname: string;
  validationType: ValidationType;
  currentValue: any;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChange: (value: any, tabKey?: -1 | 1, formula?: string) => void;
  isTextArea?: boolean;
  textareaRows?: number;
}

export function EditableFieldWithBorder({
  tableid,
  rowid,
  fieldname,
  validationType,
  currentValue,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChange, // save value AND end editing (=setEditedField(''))
  isTextArea,
  textareaRows,
}: EditableFieldWithBorderProps) {


  return (
    <div>
      <EditableField
        tableid={tableid}
        rowid={rowid}
        fieldname={fieldname}
        validationType={validationType}
        currentValue={currentValue}
        isClickableToEdit={isClickableToEdit}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        callbackCommitChange={callbackCommitChange}
        isTextArea={isTextArea}
        textareaRows={textareaRows}
        getDisplayValue={(value) => {

          const isNum = typeof value === 'number';
          const text = isTextArea ? convertLfToBr(value) : isNum ? formatNum(value) : value

          return (
            <div className={`${getEditableFieldBorderStyle(isClickableToEdit)} tw-px-3 tw-py-1 ${isNum ? 'tw-text-right' : ''}`}
              style={{
                minHeight: `${2 * (textareaRows || 1)}rem`,
              }}>
              {text}
            </div>
          )
        }}
      />
    </div>
  )

}
