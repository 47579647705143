import { Firestore, addDoc, collection } from 'firebase/firestore';
import stringify from 'json-stable-stringify';
import { UserDetailsBasicType, UserSimpleUidType } from 'src/types/types_user';
import { LevelType, LogKeyType, LogMessageType } from '../types/types_logging';
import { serverTimestampAsDate } from './util_firestoredates';


function logmsg(
  db: Firestore,
  userDetails: UserDetailsBasicType | undefined,
  level: LevelType,
  logkey: LogKeyType,
  desc: string,
  errorObj: any,
  errorData: any,
) {

  if (!db || !userDetails || !level || !desc)
    throw new Error('Missing parameter in logmsg')

  let user: UserSimpleUidType
  let userAs: UserSimpleUidType | null
  if (userDetails.trueUid) {
    user = {
      uid: userDetails.trueUid,
      email: userDetails.trueEmail ?? '',
      name: userDetails.trueName ?? '',
    }
    userAs = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
  } else {
    user = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
    userAs = null
  }

  const logEntry: Omit<LogMessageType, 'id'> = {
    level,
    logkey,
    desc,
    user,
    userAs,
    dateCreated: serverTimestampAsDate(),
  }
  if (errorObj) {
    logEntry.error = {
      name: errorObj.name ?? null,            // firestore doesn't accept 'undefined' values so
      errorCode: errorObj.code ?? null,       // we use the nullish coalescing operator here.
      errorMessage: errorObj.message ?? null, // (errorObj.code is sometimes undefined)
    }
  }
  if (errorData) {
    // While errorObj is specifically an Error object with predetermined fields,
    // errorData can be any arbitrary data that needs to be logged.
    logEntry.errorData = stringify(errorData, { space: 2 }) ?? ''
  }

  console.log(`LOG ENTRY ${level}: ${desc}`)

  addDoc(collection(db, 'logmessages'), logEntry)
  // here we don't catch, because unhandled errors are logged to the console anyway,
  // and we can't do anything more than just log to the console, so there's no point.

}

export function log_error({
  db,
  userDetails,
  logkey,
  desc,
  errorObj,
  errorData,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType | undefined,
  logkey: LogKeyType,
  desc: string,
  errorObj: any,
  errorData: any,
}) {
  logmsg(db, userDetails, 'error', logkey, desc, errorObj, errorData)
}

export function log_info({
  db,
  userDetails,
  logkey,
  desc,
  errorData,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType,
  logkey: LogKeyType,
  desc: string,
  errorData?: any,
}) {
  logmsg(db, userDetails, 'info', logkey, desc, null, errorData)
}

export function log_possible_bug({
  db,
  userDetails,
  logkey,
  desc,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType,
  logkey: LogKeyType,
  desc: string,
}) {
  logmsg(db, userDetails, 'possible_bug', logkey, desc, null, null)
}

export function log_db_read({
  db,
  userDetails,
  logkey,
  desc,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType,
  logkey: LogKeyType,
  desc: string,
}) {
  logmsg(db, userDetails, 'db_read', logkey, desc, null, null)
}

export function log_db_write({
  db,
  userDetails,
  logkey,
  desc,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType,
  logkey: LogKeyType,
  desc: string,
}) {
  logmsg(db, userDetails, 'db_write', logkey, desc, null, null)
}

export function log_page_path({
  db,
  userDetails,
  logkey,
  desc,
}: {
  db: Firestore,
  userDetails: UserDetailsBasicType,
  logkey: LogKeyType,
  desc: string,
}) {
  logmsg(db, userDetails, 'page_path', logkey, desc, null, null)
}
