import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { HotelNightType, HotelRoomType, HotelTripQuotationRowType } from 'src/types/types_tripquotations';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { formatNum } from 'src/util/util_formatnum';



interface HotelPriceBreakdownTableProps {
  row: HotelTripQuotationRowType;
  hideBreakdown: () => void;
}

export function HotelPriceBreakdownTable({
  row,
  hideBreakdown,
}: HotelPriceBreakdownTableProps) {

  const [hotelBreakdownStyle, setHotelBreakdownStyle] = useState<'room_first' | 'night_first'>('room_first');

  return (
    <div className='tw-w-fit'>
      <div className='tw-flex tw-justify-between tw-bg-slate-100 tw-p-1'>
        <div className='tw-flex tw-gap-2 tw-items-baseline'>
          <div className='tw-text-sm'>Breakdown:</div>
          <ButtonTW variant={hotelBreakdownStyle === 'room_first' ? 'blue' : 'blue_outline'} onClick={() => {
            setHotelBreakdownStyle('room_first');
          }}>
            Room by room
          </ButtonTW>
          <ButtonTW variant={hotelBreakdownStyle === 'night_first' ? 'blue' : 'blue_outline'} onClick={() => {
            setHotelBreakdownStyle('night_first');
          }}>
            Night by night
          </ButtonTW>
        </div>
        <div>
          <button className='tw-bg-transparent tw-border-none' onClick={() => {
            hideBreakdown();
          }}><i className='bi bi-x-square tw-text-lg'></i></button>
        </div>
      </div>
      {hotelBreakdownStyle === 'room_first' ? (
        [1].map(() => {

          const roomRows: JSX.Element[][] = [];
          let grandTotal = 0;
          let totalNumPax = 0;
          let overallNumOfNights: number | null = row.hotelRoomList[0]?.hotelNightList?.length ?? null;

          row.hotelRoomList.forEach((room, roomIndex) => {
            let roomTotal = 0;
            let roomTotalPerPax = 0;
            let overallNumOfPax = room.hotelNightList[0]?.numOfPax ?? null;
            const tableRowsOneRoom: JSX.Element[] = []
            room.hotelNightList.forEach((night, nightIndex) => {
              roomTotal += night.totalPrice || 0;
              roomTotalPerPax += night.pricePerPax || 0;
              if (night.numOfPax !== overallNumOfPax)
                overallNumOfPax = null;
              tableRowsOneRoom.push(
                <tr key={night.id}>
                  {nightIndex === 0 && (
                    <td rowSpan={room.hotelNightList.length + 1} className='tw-align-top'>
                      Room {roomIndex + 1}
                    </td>
                  )}
                  <td>Night {nightIndex + 1}: {dateisoFormatJpShort(night.dateiso)}</td>
                  <td>{formatNum(night.pricePerPax)}</td>
                  <td>{night.numOfPax}</td>
                  <td>{formatNum(night.totalPrice)}</td>
                </tr>
              );
            });
            tableRowsOneRoom.push(
              <tr key='row_total'>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>Room total for {room.hotelNightList.length} {room.hotelNightList.length === 1 ? 'night' : 'nights'}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(roomTotalPerPax)}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{overallNumOfPax}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(roomTotal)}</td>
              </tr>
            )
            roomRows.push(tableRowsOneRoom);
            grandTotal += roomTotal;
            totalNumPax += overallNumOfPax || 0;
            if (overallNumOfNights !== room.hotelNightList.length) {
              overallNumOfNights = null;
            }
          })


          return (
            <table key='1' className='
                                          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                                          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-mb-2'>
              <thead>
                <tr>
                  <th className='tw-bg-slate-200'>Room</th>
                  <th className='tw-bg-slate-200'>Night</th>
                  <th className='tw-bg-slate-200'>Price per person per night</th>
                  <th className='tw-bg-slate-200'>Pax per room</th>
                  <th className='tw-bg-slate-200'>Price per room per night</th>
                </tr>
              </thead>
              <tbody>
                {roomRows}
                <tr>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2' colSpan={2}>Grand total for {row.hotelRoomList.length} {row.hotelRoomList.length === 1 ? 'room' : 'rooms'} × {overallNumOfNights || '??'} {overallNumOfNights === 1 ? 'night' : 'nights'}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{grandTotal && totalNumPax ? formatNum(grandTotal / totalNumPax) : null}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{totalNumPax}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(grandTotal)}</td>
                </tr>
              </tbody>
            </table>
          );
        })
      ) : (
        [1].map(() => {
          const dateMap = new Map<string, { room: HotelRoomType, night: HotelNightType }[]>();
          for (const room of row.hotelRoomList) {
            for (const night of room.hotelNightList) {
              const dateData = dateMap.get(night.dateiso) || [];
              dateData.push({ room, night });
              dateMap.set(night.dateiso, dateData);
            }
          }

          const dates = Array.from(dateMap.keys()).sort();

          const nightRows: JSX.Element[][] = [];
          let grandTotal = 0;
          let overallNumPax: number | null | undefined = undefined; // undefined=not set yet; null=not all the same
          let totalPricePerPax = 0;

          dates.forEach((dateiso, nightIndex) => {
            let nightTotal = 0;
            let nightTotalNumOfPax = 0;

            const rooms = dateMap.get(dateiso)!;
            const tableRows: JSX.Element[] = []
            let overallPricePerPax = rooms[0].night.pricePerPax || null;
            rooms.forEach(({ room, night }, roomIndex) => {

              nightTotal += night.totalPrice || 0;
              nightTotalNumOfPax += night.numOfPax || 0;
              if (night.pricePerPax !== overallPricePerPax)
                overallPricePerPax = null;
              tableRows.push(
                <tr key={night.id}>
                  {roomIndex === 0 && (
                    <td rowSpan={rooms.length + 1} className='tw-align-top'>
                      Night {nightIndex + 1}: {dateisoFormatJpShort(dateiso)}
                    </td>
                  )}
                  <td>Room {roomIndex + 1}</td>
                  <td>{formatNum(night.pricePerPax)}</td>
                  <td>{night.numOfPax}</td>
                  <td>{formatNum(night.totalPrice)}</td>
                </tr>
              );


            });
            tableRows.push(
              <tr key='row_total'>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>Night total for {rooms.length} {rooms.length === 1 ? 'room' : 'rooms'}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(overallPricePerPax)}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{nightTotalNumOfPax}</td>
                <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(nightTotal)}</td>
              </tr>
            )
            nightRows.push(tableRows);
            grandTotal += nightTotal;
            totalPricePerPax += overallPricePerPax || 0;
            if (overallNumPax === undefined) {
              overallNumPax = nightTotalNumOfPax;
            } else if (overallNumPax !== nightTotalNumOfPax) {
              overallNumPax = null;
            }
          })

          return (
            <table key='1' className='
                                          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                                          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-mb-2'>
              <thead>
                <tr>
                  <th className='tw-bg-slate-200'>Night</th>
                  <th className='tw-bg-slate-200'>Room</th>
                  <th className='tw-bg-slate-200'>Price per person per night</th>
                  <th className='tw-bg-slate-200'>Pax per room</th>
                  <th className='tw-bg-slate-200'>Price per room per night</th>
                </tr>
              </thead>
              <tbody>
                {nightRows}
                <tr>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2' colSpan={2}>Grand total for {dates.length} {dates.length === 1 ? 'night' : 'nights'} × {row.hotelRoomList.length} {row.hotelRoomList.length === 1 ? 'room' : 'rooms'}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(totalPricePerPax)}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{overallNumPax}</td>
                  <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(grandTotal)}</td>
                </tr>
              </tbody>
            </table>
          );
        })
      )}
    </div>
  )
}
