import { RiArrowDownDoubleFill } from 'react-icons/ri';
import { useAppContext } from 'src/hooks/useAppContext';
import { log_db_write } from 'src/util/util_log';


interface SourceDataGrayBoxProps {
  disabled?: boolean;
  onArrowClick?: () => void;
  children: React.ReactNode;
}

export function SourceDataGrayBox({
  disabled,
  onArrowClick,
  children,
}: SourceDataGrayBoxProps) {

  const { db, userDetails } = useAppContext();

  if (!children)
    return null;

  return (
    <div className='
      tw-text-sm tw-text-slate-800 tw-bg-slate-200
      tw-border-r-0.25 tw-border-b-0.25 tw-border-l-0 tw-border-t-0
      tw-border-solid tw-border-slate-400
      tw-p-0.5 tw-relative
    '>
      {children}
      <span>&nbsp;&nbsp;&nbsp;</span>{/* nbsp are to prevent the double arrow from appearing on top of text */}
      {onArrowClick && !disabled && (
        <div className='tw-absolute tw-right-1 tw-bottom-0 tw-text-lg tw-text-slate-600 tw-cursor-pointer' onClick={() => {
          log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.copy_gray_box_text', desc: `Copy gray box text: ${children}` });
          onArrowClick();
        }}>
          <RiArrowDownDoubleFill />
        </div>
      )}
    </div>
  )

}
